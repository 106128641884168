import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const FooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20vh 3vw 2%;

  @media (max-width: ${breakpoints.screenLG}) {
    padding: 200px 30px 50px;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    padding: 200px 30px 40px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    padding: 200px 20px 30px;
  }

  .form-title {
    margin-bottom: 1%;

    &.last {
      margin-bottom: 20%;
      margin-bottom: 63px;
    }

    &.zanzibar {
      font-size: 3.5vw;
    }

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 68px;
      margin-bottom: 6px;
      width: fit-content;

      &.zanzibar {
        font-size: 48px;
      }

      &.last {
        margin-bottom: 90px;
      }
    }

    @media (max-width: ${breakpoints.screenSM}) {
      font-size: 15vw;

      &.zanzibar {
        font-size: 10.5vw;
      }
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-container {
    position: relative;
    margin-bottom: 27vh;

    @media (max-width: ${breakpoints.screenLG}) {
      margin-bottom: 300px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      width: 100%;
      margin-bottom: 230px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 0 0.3vw;
  }

  input {
    font-size: 1.45vw;
    color: ${({ theme, isReverse }) =>
      isReverse ? theme.textLight : theme.textPrimary};
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.05em;
    border: 0;
    border-radius: 0;
    border-bottom: 0.15vw solid
      ${({ theme, isReverse }) =>
        isReverse ? theme.textLight : theme.textPrimary};
    background-color: transparent;
    padding-left: 0;
    padding-bottom: 6%;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 22px;
      border-width: 2px;
    }

    ::placeholder {
      font-family: 'Space Grotesk', sans-serif;
      color: ${({ theme, isReverse }) =>
        isReverse ? theme.textLight : theme.textPrimary};
      font-weight: 500;
      letter-spacing: -0.05em;
    }

    :nth-child(1) {
      margin-bottom: 18%;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-bottom: 70px;
      }
    }

    :nth-child(2) {
      width: 70%;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;

    svg {
      width: 2.2vw;
      height: auto;

      @media (max-width: ${breakpoints.screenLG}) {
        width: auto;
      }
    }
  }

  .message {
    position: absolute;
    left: 0;
    margin-top: 5%;
  }
`

export { FooterStyled }
