import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import { useTheme } from 'styled-components'

import { BodyS } from '../styles/Typography'
import { LegalRowStyled } from '../styles/LegalRowStyled'

/**
 * The legal row included in the footer.
 * @param {ComponentProps} props
 * @returns {StatelessComponent}
 */
const LegalRow = ({ isReverse }) => {
  const theme = useTheme()

  return (
    <LegalRowStyled>
      <BodyS
        color={isReverse ? theme.textLight : theme.textPrimary}
        weight={500}
      >
        © Copyright {moment().year()} — Butik Electronic Music Festival
      </BodyS>
      <div className="legal-links">
        <Link to="/privacy-policy">
          <BodyS
            color={isReverse ? theme.textLight : theme.textPrimary}
            weight={500}
          >
            Privacy Policy
          </BodyS>
        </Link>
        <Link to="/privacy-policy#cookie-policy">
          <BodyS
            color={isReverse ? theme.textLight : theme.textPrimary}
            weight={500}
          >
            Cookie Policy
          </BodyS>
        </Link>
        <Link to="/privacy-policy#ticket-terms-conditions">
          <BodyS
            color={isReverse ? theme.textLight : theme.textPrimary}
            weight={500}
          >
            Ticket Terms & Conditions
          </BodyS>
        </Link>
      </div>
    </LegalRowStyled>
  )
}

export default LegalRow
