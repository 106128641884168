import styled from 'styled-components'

const PageLayout = styled.div`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  will-change: background-color;
  transition: background-color 0.3s linear;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

export { PageLayout }
